/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 48번째 로그에서는 파이어폭스 69 릴리스, AWS 도쿄 리전 장애, 리눅스 exFAT 지원 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.h2, null, "후원 / 근황"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.patreon.com/stdoutfm"
  }, "stdout.fm are creating 클라우드, 소프트웨어 개발, 전자 제품에 대해 이야기하는 프로그래머들의 팟캐스트 | Patreon")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.amazon.com/Rode-RODECaster-Podcast-Production-Studio/dp/B07M5LQ1YZ/"
  }, "Amazon.com: Rode RODECaster Pro Podcast Production Studio: Musical Instruments")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://reinvent.awsevents.com/"
  }, "AWS re:Invent 2019")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://2019.djangocon.us/"
  }, "DjangoCon US 2019 • September 22-27, 2019 • San Diego, CA United States | DjangoCon US")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.amazon.com/Sound-Devices-MixPre-3-Portable-Interface/dp/B075SP74RZ/ref=sr_1_5?keywords=mix+pre-3&qid=1567990036&s=gateway&sr=8-5"
  }, "Amazon.com: Sound Devices MixPre-3 Portable Audio Recorder & USB Interface: Gateway")), "\n"), "\n", React.createElement(_components.h2, null, "Firefox 69 릴리스"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.zdnet.com/article/firefox-69-is-out-flash-squeezed-out-tracking-protection-on-by-default/"
  }, "Firefox 69 is out: Flash squeezed out, tracking protection on by default | ZDNet")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.mozilla.org/firefox/how-to-block-fingerprinting-with-firefox/"
  }, "How to block fingerprinting with Firefox | The Firefox Frontier")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://brave.com/"
  }, "Secure, Fast & Private Web Browser with Adblocker | Brave Browser")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://4e6.github.io/firefox-lang-stats/"
  }, "Language details of the Firefox repo")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://addons.mozilla.org/en-US/firefox/addon/auto-tab-discard/"
  }, "Auto Tab Discard – Get this Extension for 🦊 Firefox (en-US)")), "\n"), "\n", React.createElement(_components.h2, null, "8월 23일 AWS 도쿄 리전 장애"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/jp/message/56489/"
  }, "Summary of the Amazon EC2 Issues in the Asia Pacific (Tokyo) Region (AP-NORTHEAST-1)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://tech.nikkeibp.co.jp/atcl/nxt/column/18/00001/02776/"
  }, "(일본어) AWS大障害、ユニクロ・楽天・PayPayなど30社以上に影響 | 日経 xTECH（クロステック）")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/message/74876/"
  }, "아시아-태평양 서울 리전(AP-NorthEast-2)의 Amazon EC2 DNS 확인(Resolution) 이슈 요약")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techcrunch.com/2018/06/08/why-microsoft-wants-to-put-data-centers-at-the-bottom-of-the-ocean/"
  }, "Why Microsoft wants to put data centers at the bottom of the ocean | TechCrunch")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blogs.technet.microsoft.com/jpaztech/2017/04/03/rca34296489/"
  }, "(일본어) 3 月 31 日夜間から発生した東日本データセンターの障害についての原因調査報告書 (RCA) の抄訳 – Japan Azure IaaS Support Blog")), "\n"), "\n", React.createElement(_components.h2, null, "타오바오 나스 사태"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://gigglehd.com/gg/hard/5550563"
  }, "타오바오 NAS는 왜 저렴하게 판매되는가? - 컴퓨터 / 하드웨어 - 기글하드웨어")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.m.wikipedia.org/wiki/%EB%A1%B1%ED%85%80%EC%BA%90%ED%94%BC%ED%84%B8%EB%A7%A4%EB%8B%88%EC%A7%80%EB%A8%BC%ED%8A%B8"
  }, "롱텀캐피털매니지먼트 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.shalphareit.com/html/main.html"
  }, "신한알파리츠")), "\n"), "\n", React.createElement(_components.h2, null, "리눅스 커널에서 exFAT 지원"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cloudblogs.microsoft.com/opensource/2019/08/28/exfat-linux-kernel/?utm_source=t.co&utm_medium=referral"
  }, "exFAT in the Linux kernel? Yes! - Open Source Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/File_Allocation_Table"
  }, "File Allocation Table - Wikipedia")), "\n"), "\n", React.createElement(_components.h2, null, "Hashicorp 사용자 모임 9월 서울 밋업"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/473"
  }, "하시코프 사용자 모임 2019년 9월 서울 밋업 | Festa!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/help"
  }, "헬프 데스크 | Festa!")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
